<template>
    <VModal name="confirmDeleteSportsTypeModal" class="notification-modal centered" width="100%" height="auto" :scrollable="true">
      <div class="vm-content">
        <img src="@/assets/notification/ic_notification_warning.svg" alt="">
        <h3>
          Bist du sicher?
        </h3>
        <p>
          Möchtest du die indiviuell hinzugefügte Trainingsart "{{ sportsTypeName }}" entfernen?
        </p>
        <v-btn type="button" class="button button--primary button--small" @click="reallyDelete">Ja, entfernen</v-btn>
        <v-btn type="button" class="button button--secondary button--small" @click="dismissDialog">Nein, abbrechen</v-btn>
      </div>
    </VModal>
</template>

<style lang="scss">
</style>
<script>
export default {
    name: 'ConfirmDeleteSportsTypeModal',
    props: {
        sportsTypeName: {
            type: String,
            default() { return ""; } 
        }
    },
    methods: {
        dismissDialog() {
            this.$modal.hide('confirmDeleteSportsTypeModal');
        },
        reallyDelete() {
            this.$modal.hide('confirmDeleteSportsTypeModal');
            this.$emit("reallyDelete", this.sportsTypeName);
        },
    }
}
</script>