<template>
    <div v-if="!premiumStatus.validTo" class="list">
        <label class="list__item">
            <div class="list__item__content">
            <div class="list__item__label">
                Premium Inhalte zeigen
            </div>
            </div>
            <div class="toggle">
                <input v-model="currentValue" @change="valueChanged" type="checkbox">
                <span class="toggle__button"></span>
            </div>
        </label>
    </div>
</template>
<script>
export default {
    name: 'PremiumContentsToggle',
    methods: {
        valueChanged() {
            this.$emit('valueChanged', this.currentValue);
        }
    },
    mounted() {
        this.currentValue = this.showPremiumContents;
    },
    data() {
        return {
            currentValue: false,
        }
    },
    computed: {
        premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        }
    },
    props: {
        showPremiumContents: {
            type: Boolean,
            default() { return true; } 
        }
    },
}
</script>
