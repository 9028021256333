<template>
	<div class="fitnessplanCreateExercises" :class="selectedActivity ? 'exercise-selected': ''">
		<!-- FIXME remove obsolete tabs -->
		<!-- TABS -->
		<div class="tabs tabs--fullwidth">
			<div class="tabs__content">
				<v-btn v-if="recentActivities.length > 0" @click="showRecentActivities" :class="activeTab == tabNames.TAB_RECENT ? 'is-active' : ''" text v-ripple="false">Letzte</v-btn>
				<!-- <v-btn @click="showAllPlaylists" :class="activeTab == tabNames.TAB_PLAYLISTS || activeTab == tabNames.TAB_PLAYLISTS_BY_SPORTSTYPE || activeTab == tabNames.TAB_PLAYLISTS_BY_COACH ? 'is-active' : ''" text v-ripple="false">Playlists</v-btn> -->
				<v-btn @click="showAllWorkouts" :class="activeTab == tabNames.TAB_WORKOUTS ? 'is-active' : ''" text v-ripple="false">Workouts</v-btn>
				<v-btn @click="showCustomActivities"  :class="activeTab == tabNames.TAB_OTHER ? 'is-active' : ''" text v-ripple="false">Sonstige</v-btn>
			</div>
		</div>

		<div v-if="activeTab == tabNames.TAB_RECENT" class="fitnessplanCreateExercises__bottomMargin">

			<!-- Hint Text -->
			<div class="text-center">
				<small>Hier findest du deine zuletzt ausgewählten Workouts</small>
			</div>

			<template v-for="activity in recentActivities">
				<WorkoutItem v-if="activityType(activity) == 'workout.WorkoutPage'" @selectWorkout=selectActivity :workout="activity" :selectOnly="true"
				:class="isSelected(activity) ? 'is-selected': ''" v-bind:key="activity.id"></WorkoutItem>
				
				<PlaylistItem v-else-if="activityType(activity) == 'exercise.PlaylistPage'" @selectPlaylist=selectActivity :playlist="activity" :selectOnly="true"
				:class="isSelected(activity) ? 'is-selected': ''" v-bind:key="activity.id"></PlaylistItem>
				
				<!-- key is negated to avoid duplicates and update errors -->
				<div class="section" v-else-if="activityType(activity) == 'Category'" v-bind:key="-activity.id">
					<div @click="selectActivity(activity)" :class="isSelected(activity) ? 'card is-selected' : 'card'">
						<div class="card__title">{{ activity.name }}</div>
						<div class="card__subtitle">{{ activity.subtitle }}</div>
						<img class="card__image" :src="'https://app.sportlerplus.com/scaled/800/' + activity.image_url" alt="" />
					</div>
				</div>

				<div class="section" v-else-if="activityType(activity) == 'Custom/SportsType'" v-bind:key="activity.id">
					<div @click="selectActivity(activity)" :class="isSelected(activity) ? 'card is-selected' : 'card'">
						<div class="card__title">{{ activity.name }}</div>
						<div class="card__subtitle">{{ activity.subtitle }}</div>
						<img class="card__image" :src="'https://app.sportlerplus.com/scaled/800/' + activity.image_url" alt="" />
					</div>
				</div>

				<div class="section" v-else-if="activityType(activity) == 'Custom/UserPlaylist'" v-bind:key="activity.id">
					<PlaylistPersonalItem 
						@startPlaylist="selectActivity(activity)"
						@editPlaylistPersonalItem="selectActivity(activity)"
						:title="activity.name" :number="activity.items.length"
						:class="isSelected(activity) ? 'is-selected': ''"></PlaylistPersonalItem>
				</div>
			</template>
		</div>

		<div v-else-if="activeTab == tabNames.TAB_WORKOUTS">

			<!-- Hint Text -->
			<!-- <div class="text-center">
			<small>Hier findest Du von unseren Coaches moderierte Full-Workouts, die in Intensität, Dauer und Inhalt variieren.</small>
			</div> -->

			<!-- <PremiumContentsToggle @valueChanged="setShowPremiumContents" :showPremiumContents="showPremiumContents"></PremiumContentsToggle> -->

			<div v-if="userPlaylists != null && userPlaylists.length > 0" class="section">
				<div class="section__title">
					<h4>Meine Workouts</h4>
					<a v-if="upLimit < userPlaylists.length" @click="showAllUserPlaylists">Alle anzeigen</a>
				</div>
				<PlaylistPersonalItem v-for="up in userPlaylists.slice(0, upLimit)"
					:key="up.uid"
					@startPlaylist="selectActivity(up)"
					@editPlaylistPersonalItem="selectActivity(up)"
					:title="up.name" :number="up.items.length"
					:class="isSelected(up) ? 'is-selected': ''"></PlaylistPersonalItem>
				
			</div>

			<div class="section">
				<div class="section__title">
					<h4>Workout-Kategorien</h4>
				</div>
				<div class="section__content">
					<Slider :slides="categories">
					<splide-slide v-for="category in categories" v-bind:key="category.id">
						<div @click="selectActivity(category)" :class="isSelected(category) ? 'card is-selected' : 'card'">
							<div class="card__title">
								<div class="workout__item__badge" v-if="category.badge">{{ category.badge }}</div>
								<div>{{ category.name }} Mix</div>
							</div>
							<img class="card__image" :src="'https://app.sportlerplus.com/scaled/800/' + category.image_url" alt="" />
						</div>
					</splide-slide>  
					</Slider>
				</div>
			</div>

			<div class="section">
				<div class="section__title">
					<h4 v-if="!filterActive">Alle Workouts</h4>
				</div>
				<template v-for="ti in filterResult">
					<WorkoutItem v-if="activityType(ti) == 'workout.WorkoutPage'" @selectWorkout=selectActivity  :workout="ti"
					v-bind:key="ti.id" :selectOnly="true"
					:class="isSelected(ti) ? 'is-selected': ''"></WorkoutItem>
					<PlaylistItem v-else-if="activityType(ti) == 'exercise.PlaylistPage'" @selectPlaylist=selectActivity :playlist="ti" :selectOnly="true"
					:class="isSelected(ti) ? 'is-selected': ''" v-bind:key="ti.id"></PlaylistItem>
				</template>
			</div>

			<!-- Filter Button -->
			<div @click="openWorkoutFilter" :class="[(filterActive ? 'floatingbutton floatingbutton--active' : 'floatingbutton'), (selectedActivity ? 'item-selected' : '')]"><!-- TODO active -->
				<div class="floatingbutton__icon">
					<img src="@/assets/ic_16_filter.svg" alt="Filter">
				</div>
				Filtern
			</div>

			<WorkoutFilter @closeModal="dismissWorkoutFilter" ref="workoutFilter" @resetFilter="resetFilterResult" @filterResult="applyFilterResult" :workouts="trainingItems"></WorkoutFilter>

		</div>

		<div v-else-if="activeTab == tabNames.TAB_PLAYLISTS" class="fitnessplanCreateExercises__bottomMargin">
			
			<!-- Hint Text -->
			<div class="text-center">
			<small>Hier findest Du sämtliche zur Verfügung stehenden Playlists. Wähle zwischen deinen individuellen, generellen, Sportarten- und Pro-Playlists.</small>
			</div>

			<PremiumContentsToggle @valueChanged="setShowPremiumContents" :showPremiumContents="showPremiumContents"></PremiumContentsToggle>

			<div v-if="userPlaylists != null && userPlaylists.length > 0" class="section">
				<div class="section__title">
					<h4>Meine Playlists</h4>
					<a v-if="upLimit < userPlaylists.length" @click="showAllUserPlaylists">Alle anzeigen</a>
				</div>
				<PlaylistPersonalItem v-for="up in userPlaylists.slice(0, upLimit)"
					:key="up.uid"
					@startPlaylist="selectActivity(up)"
					@editPlaylistPersonalItem="selectActivity(up)"
					:title="up.name" :number="up.items.length"
					:class="isSelected(up) ? 'is-selected': ''"></PlaylistPersonalItem>
				
			</div>

			<div class="section" v-for="category in mainCategories" v-bind:key="category.id">
				<div class="section__title">
					<h4>{{ category.name }}</h4>
				</div>
				<div class="section__content">
					<Slider :slides="subCategories(category.id)">
					<splide-slide v-for="subCategory in subCategories(category.id)" v-bind:key="subCategory.id">
						<div @click="selectActivity(subCategory)" :class="isSelected(subCategory) ? 'card is-selected' : 'card'">
							<div v-if="subCategory.parent || category.child_count == 0" class="card__title">{{ subCategory.name }}</div>
							<div v-else class="card__title">{{ subCategory.name }} Mix</div>
							<div class="card__subtitle">{{ subCategory.subtitle }}</div>
							<img class="card__image" :src="'https://app.sportlerplus.com/scaled/800/' + subCategory.image_url" alt="" />
						</div>
					</splide-slide>  
					</Slider>
				</div>
			</div>

			<!-- SPORTARTEN -->
			<div class="section">
			<div class="section__title">
				<h4>Meine Sportarten</h4>
			</div>
			<div class="section__content">
				<Slider :slides="userSportsTypes">
					<splide-slide v-for="sportsType in userSportsTypes" v-bind:key="sportsType.id">
						<div class="card" @click="showPlaylistsBySportsType(sportsType)">
							<div class="card__title">{{ sportsType.name }}</div>
							<div class="card__subtitle"></div>
							<img v-if="sportsType.image_url" class="card__image" :src="'https://app.sportlerplus.com/scaled/800/' + sportsType.image_url" alt="" />
						</div>
					</splide-slide>  
				</Slider>
			</div>
			</div>

			<!-- YOUR PRO -->
			<div class="section">
				<div class="section__title">
				<h4>Your Pro</h4>
				</div>
				<div class="section__content">
				<SliderSmall :slides="coaches">
					<splide-slide v-for="coach in coaches" v-bind:key="coach.id"> 
					<div @click="showPlaylistsByCoach(coach)" class="yourpro__item">
						<img :src="'https://app.sportlerplus.com/scaled/800/' + coach.image_url" alt="" class="yourpro__item__image">
						<h3 class="yourpro__item__title">
						<div class="yourpro__item__badge">{{ coach.badge }}</div>
						{{ coach.name }}
						</h3>
					</div>
					</splide-slide>
				</SliderSmall>
				</div>
			</div>

		</div>

		<div v-else-if="activeTab == tabNames.TAB_PLAYLISTS_BY_SPORTSTYPE">
			<PremiumContentsToggle @valueChanged="setShowPremiumContents" :showPremiumContents="showPremiumContents"></PremiumContentsToggle>

			<PlaylistItem @selectPlaylist=selectActivity v-for="playlist in playlists" :playlist="playlist"
				v-bind:key="playlist.id" :selectOnly="true"
				:class="isSelected(playlist) ? 'is-selected': ''"></PlaylistItem>
		</div>

		<div v-else-if="activeTab == tabNames.TAB_PLAYLISTS_BY_COACH">
			<PremiumContentsToggle @valueChanged="setShowPremiumContents" :showPremiumContents="showPremiumContents"></PremiumContentsToggle>

			<PlaylistItem @selectPlaylist=selectActivity v-for="playlist in coachPlaylists" :playlist="playlist"
				v-bind:key="playlist.id" :selectOnly="true"
				:class="isSelected(playlist) ? 'is-selected': ''"></PlaylistItem>
		</div>

		<div v-else-if="activeTab == tabNames.TAB_OTHER" class="fitnessplanCreateExercises__bottomMargin">
			<NewCustomSportsType :userInformation=userInformation @addSportsType=updateUserInformation></NewCustomSportsType>
			<ConfirmDeleteSportsTypeModal :sportsTypeName=sportsTypeToDelete @reallyDelete=deleteCustomSportsType></ConfirmDeleteSportsTypeModal>

			<!--<div class="section" v-for="sportsType in sportsTypes" v-bind:key="sportsType.id">
				<div @click="selectActivity(sportsType)" :class="isSelected(sportsType) ? 'card is-selected' : 'card'">
					<div class="card__title">{{ sportsType.name }}</div>
					<div class="card__subtitle"></div>
					<img class="card__image" :src="'https://app.sportlerplus.com/scaled/800/' + sportsType.image_url" alt="" />
				</div>
			</div>-->

			<div class="add-sports-button" @click="openNewSportsTypeModal">
				<img src="@/assets/ic_plus.svg" alt="">
				Trainingsart hinzufügen
			</div>

			<div class="selection-cards">
				<div :class="isSelected(sportsType) ? 'selection-cards__item selection-cards__item--selected' : 'selection-cards__item'" v-for="sportsType in allSportsTypes" v-bind:key="sportsType.id">
					<div v-if="sportsType.deletable" class="selection-cards__item__button" @click="confirmDeleteCustomSportsType(sportsType.name)">
						<img src="@/assets/ic_plus.svg">
					</div>
					<label @click="selectActivity(sportsType)">
						<div class="selection-cards__item__label">{{ sportsType.name }}</div>
					</label>
				</div>
			</div>
		</div>

		<v-btn :disabled="!selectedActivity" @click="finaliseSelection" class="button button--primary">Bestätigen</v-btn>

	</div>
</template>

<script>
import WorkoutItem from "@/components/WorkoutItem.vue";
import PlaylistItem from '@/components/PlaylistItem.vue';
import Slider from "@/components/Slider.vue";
import SliderSmall from "@/components/SliderSmall.vue";
import { SplideSlide } from '@splidejs/vue-splide';
import PlaylistPersonalItem from "@/components/PlaylistPersonalItem.vue";
import PremiumContentsToggle from "@/components/PremiumContentsToggle.vue";
import NewCustomSportsType from "@/components/NewCustomSportsType.vue";
import ConfirmDeleteSportsTypeModal from "@/components/ConfirmDeleteSportsTypeModal.vue";
import WorkoutFilter from "@/components/WorkoutFilter.vue";

export default {
	name: 'FitnessplanCreateExercises',
	components: { WorkoutItem, WorkoutFilter, PlaylistPersonalItem, PlaylistItem, Slider, SliderSmall, SplideSlide, PremiumContentsToggle, NewCustomSportsType, ConfirmDeleteSportsTypeModal },
	methods: {
		init() {
			this.$store.dispatch('fetchWorkouts').then( () => this.filterResult = this.trainingItems );
			this.$store.dispatch('fetchPlaylists').then( () => this.filterResult = this.trainingItems );
			this.selectedActivity = this.$store.getters.getSelectedFitnessPlanActivity;
			if (this.recentActivities.length > 0) {
				this.activeTab = this.tabNames.TAB_RECENT;
			}
			else {
				this.activeTab = this.tabNames.TAB_WORKOUTS;
			}
			this.fetchUserSportsTypes();
			this.$store.dispatch('fetchAllUserPlaylists').then((res) => {
                this.userPlaylists = res;
			});
		},
		openWorkoutFilter() {
            this.$refs.workoutFilter.collapse();
            this.$modal.show("workoutFilter");
        },

        dismissWorkoutFilter() {
          this.$modal.hide('workoutFilter');
        },
		applyFilterResult(filteredItems) {
            this.filterResult = filteredItems;
            this.filterActive = this.filterResult.length != this.trainingItems.length;
            //this.highlightCategories;
            //console.log("show moderated: " + this.$refs.workoutFilter.moderated );
            this.displayCategoryResults = this.$refs.workoutFilter.moderated != "yes";
            this.$forceUpdate();
            this.scrollToTop();
        },
        resetFilterResult() {
            this.filterResult = this.trainingItems;
            this.filterActive = false;
            this.scrollToTop();
            this.displayCategoryResults = true;
        },
		scrollToTop() {
				window.scrollTo({ top: 0, behavior: 'smooth' })
        },
		setShowPremiumContents(val) {
			this.showPremiumContents = val;
		},
		async fetchUserSportsTypes() {
            await this.$store.dispatch('fetchSportsTypes').then( () => {
                this.sportsTypes = this.$store.getters.getSportsTypes;
            });
            await this.$store.dispatch('fetchUserInformation').then( (res) => {
                if (res.length != 0) {
                    this.userInformation = res[0];

                    if (this.userInformation && this.userInformation.sports_types) {
                        this.userSportsTypes = [...this.userInformation.sports_types];
                    }
					this.userCustomSportsTypes = this.getCustomSportsTypes();                    
                }
            });
            this.sportsTypes.filter(st => !st.user_selectable).forEach(st => this.userSportsTypes.push(st));
            this.userSportsTypes.forEach(ust => {
				ust.meta = { type: "Custom/SportsType" };
                let tempST = this.sportsTypes.find(st => st.id == ust.id);
                if (tempST) {
                    ust.image_url = tempST.image_url;
					ust.square_tile_image_url = tempST.square_tile_image_url;
                }
            });
        },
		deleteCustomSportsType(name) {
			if (this.userInformation && this.userInformation.customSportsTypes) {
				var stl = this.userInformation.customSportsTypes;
				var index = stl.indexOf(name);
				if (index !== -1) {
					stl.splice(index, 1);
					this.userInformation.customSportsTypes = stl;
					this.$store.dispatch('saveUserInformation', this.userInformation);

					if (this.selectedActivity != null && this.selectedActivity.id == (10000+index)) {
						this.selectedActivity = null;
					}
				}
			}
			this.userCustomSportsTypes = this.getCustomSportsTypes();
		},
		confirmDeleteCustomSportsType(name) {
			this.sportsTypeToDelete = name;
			this.$modal.show('confirmDeleteSportsTypeModal');
		},
		openNewSportsTypeModal() {
			this.$modal.show('newCustomSportsType');
		},
		updateUserInformation() {
			this.userCustomSportsTypes = this.getCustomSportsTypes();
			this.$store.dispatch('saveUserInformation', this.userInformation);
			this.selectActivity(this.userCustomSportsTypes[this.userCustomSportsTypes.length-1]);
		},
		getCustomSportsTypes() {
			var stl = [];
			if (this.userInformation && this.userInformation.customSportsTypes) {
				this.userInformation.customSportsTypes.forEach( (it,idx) => {
					stl.push({"name": it, "id": (10000+idx), "image_url": "fitness.jpg",
					"deletable": true});
				});
			}
			return stl;
		},
		activityType(activity) {
			if (!activity) { return null; }
			if (activity.meta === undefined) { return "Category"; }
			else { return activity.meta.type; }
		},
		showAllWorkouts() {
			this.activeTab = this.tabNames.TAB_WORKOUTS;
		},
		showAllPlaylists() {
			this.activeTab = this.tabNames.TAB_PLAYLISTS;
		},
		showRecentActivities() {
			this.activeTab = this.tabNames.TAB_RECENT;
		},
		showPlaylistsBySportsType(sportsType) {
			this.selectedSportsType = sportsType;
			this.activeTab = this.tabNames.TAB_PLAYLISTS_BY_SPORTSTYPE;
		},
		showPlaylistsByCoach(coach) {
			this.selectedCoach = coach;
			this.activeTab = this.tabNames.TAB_PLAYLISTS_BY_COACH;
		},
		showCustomActivities() {
			this.activeTab = this.tabNames.TAB_OTHER;	
		},
		selectActivity(activity) {
			if (activity.id === undefined && activity.meta && activity.meta.type == "Custom/UserPlaylist") {
				activity.id = activity.uid;
			}
			if (this.selectedActivity && (this.selectedActivity.id == activity.id)) {
				this.selectedActivity = null;
			}
			else {
				this.selectedActivity = activity;
			}
		},
		finaliseSelection() {
			this.$store.commit('setSelectedFitnessPlanActivity', this.selectedActivity);
			this.$router.go(-1);
		},
		isSelected(activity) {
			if (this.selectedActivity) {
				// categories do not have meta information
				if (this.selectedActivity.meta === undefined && activity.meta === undefined) {
					return this.selectedActivity.id == activity.id;
				}
				else if (this.selectedActivity.meta && activity.meta) {
					return this.selectedActivity.id == activity.id && this.selectedActivity.meta.type == activity.meta.type;
				}
			}
			
			return false;
		},
		subCategories(parent_id) {
            let sub_categories = this.categories.filter(i => i.parent != null && i.parent.id == parent_id);
			let main_category = this.categories.find(i => i.id == parent_id);
			main_category.child_count = sub_categories.length;    
            sub_categories.push(main_category);
			if (!this.showPremiumContents) {
				sub_categories = sub_categories.filter(s => !s.premium_content);
			}
            return sub_categories;
		},
		showAllUserPlaylists() {
			this.upLimit = this.userPlaylists.length;
		}
	},
	mounted() {
		this.init();
	},
	data() {
		return {
			showPremiumContents: true,
			activeTab: null,
			selectedActivity: null,
			sportsTypes: [],
			userSportsTypes: [],
			userCustomSportsTypes: [],
			selectedSportsType: null,
			selectedCoach: null,
			userPlaylists: [],
			upLimit: 3,
			userInformation: null,
			sportsTypeToDelete: null,
			filterActive: false,
			displayCategoryResults: true,
			filterResult: null,
			tabNames: Object.freeze({ "TAB_RECENT": 1, "TAB_OTHER": 2, "TAB_PLAYLISTS": 3, "TAB_WORKOUTS": 4,
"TAB_PLAYLISTS_BY_SPORTSTYPE": 5, "TAB_PLAYLISTS_BY_COACH": 6 })
		}
	},
	computed: {
		workouts() {
			let workouts = this.$store.getters.getWorkouts;
			if (!this.showPremiumContents) {
				workouts = workouts.filter(w => !w.premium_content);
			}
			return workouts.sort((a,b) => (a.title.localeCompare(b.title)));
		},
		playlists() {
			let playlists = this.$store.getters.getPlaylists;
			if (this.selectedSportsType != null) {
				playlists = playlists.filter(pl => {
					if (pl.sports_types) {
						if (pl.sports_types.find(st => st.id == this.selectedSportsType.id)) return true;
					}
					return false;
				});
			}
			if (!this.showPremiumContents) {
				playlists = playlists.filter(p => !p.premium_content);
			}
			return playlists.sort((a,b) => (a.title.localeCompare(b.title)));
		},
		trainingItems() {
			var workouts = this.$store.getters.getWorkouts;
			var playlists = this.$store.getters.getPlaylists;
            var trainingItems = playlists.concat(workouts);
            return trainingItems.sort((a, b) => {
                var d1 = new Date(a.go_live_at);
                var d2 = new Date(b.go_live_at);
                return d2 - d1;
             });
        },
        coachPlaylists() {
			let playlists = this.$store.getters.getPlaylists;
			if (!this.selectedCoach) { return []; }
            playlists = playlists.filter(p => p.coach != null && p.coach.id == this.selectedCoach.id);
			if (!this.showPremiumContents) {
				playlists = playlists.filter(p => !p.premium_content);
			}
			return playlists.sort((a,b) => (a.title.localeCompare(b.title)))
		},
		categories() {
			let categories = this.$store.getters.getCategories;
			return categories /*.sort((a,b) => (a.name.localeCompare(b.name)));*/
		},
		recentActivities() {
			return this.$store.getters.getRecentFitnessPlanActivities;
		},
        mainCategories() {
			if (!this.showPremiumContents) {
				return this.trialCategories;
			}
            return this.categories.filter(i => i.parent == null);
		},
		coaches() {
			return this.$store.getters.getCoaches;
		},
		premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
		trialCategories() {
            if (this.categories) {
                return this.categories.filter(a => !a.premium_content);
            }
            return [];
        },
		allSportsTypes() {
			var result = this.userCustomSportsTypes.concat(this.sportsTypes);
			result.forEach(r => r.meta = { type: "Custom/SportsType" });
			return result;
		}
	}
}
</script>

<style lang="scss">
	.fitnessplanCreateExercises {
		display: flex;
		flex-direction: column;
		min-height: 100%;

		&.exercise-selected {
			padding-bottom: 56px;
		}

		small {
			display: flex;
			margin-bottom: 20px;
		}

		.tabs {
			margin-top: -24px;
		}

		&__bottomMargin {
			margin-bottom: 16px;
		}

		> .button--primary {
			margin-top: auto;
			position: fixed;
			bottom: 16px;
			width: calc(100% - 40px);
			z-index: 10;

			&[disabled] {
				display: none;
			}
		}

		.add-sports-button {
			display: flex;
			justify-content: center;
			border-radius: 4px;
			font-size: 14px;
			font-weight: 600;
			color: rgba($colorBlack, .48);
			background: rgba($colorBlack, .06);
			padding: 16px 8px;
			margin-bottom: 20px;
			margin-top: 4px;
			cursor: pointer;

			img {
				opacity: .48;
				margin-right: 8px;
			}
		}

		.card {
			transition: all .2s ease-in-out;
		}

		.is-selected {
			box-shadow: 0 0 0 2px $colorPrimary, 0 8px 16px 0 rgba(248,121,24,0.24);
		}

		.not-selected {
			opacity: .32;
		}

		.workout__item {
			margin-bottom: 16px !important;
		}

		.contentItem__actions {
			display: none;
		}

		.splide {
			&__track {
				margin: -20px 0;
				padding: 20px 0;
			}
			&__slide {
				padding: 2px 0;
			}
		}
	}
</style>

<style lang="scss" scoped>
	.floatingbutton {
		bottom: 16px;

		&.item-selected {
			bottom: 92px;
		}
	}
</style>