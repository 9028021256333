<template>
    <VModal name="newCustomSportsType" class="centered text-center" width="100%" height="auto" :scrollable="true">
        <div class="vm-header">
            <div class="vm-header-left">
            </div>
            <div class="vm-header-center">
                Neue Trainingsart
            </div>
            <div class="vm-header-right">
                <img src="@/assets/ic_nav_close.svg" alt="" @click="dismissDialog">
            </div>
        </div>
        <div class="vm-content">
            <p>
                Gib den Namen deiner individuellen Trainingsart an.
            </p>
            <v-text-field v-model="newSportsTypeName" placeholder="z.B. Seilspringen"></v-text-field>
            <v-btn type="button" class="button button--primary button--small" @click="createNewSportsType">Hinzufügen</v-btn>
        </div>
    </VModal>
</template>

<style lang="scss">
</style>
<script>
export default {
    name: 'NewCustomSportsType',
    data() {
        return {
            newSportsTypeName: "",
        }
    },
    props: {
        userInformation: {
            type: Object,
            default() { return {}; } 
        }
    },
    methods: {
        createNewSportsType() {
            if (this.userInformation) {
                this.userInformation.customSportsTypes = this.userInformation.customSportsTypes || [];
                this.userInformation.customSportsTypes.push(this.newSportsTypeName);
            }
            this.$modal.hide('newCustomSportsType');
            this.newSportsTypeName = "";
            this.$emit("addSportsType");
        },
        dismissDialog() {
            this.$modal.hide('newCustomSportsType');
            this.newSportsTypeName = "";
        }
    } 
}
</script>